import { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/index.css';
import axios from 'axios';

import LogoFyxdHistory from '../images/logos/fyxd-history-color.svg';
import { MsalAuthenticationTemplate, useMsal, useIsAuthenticated } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from '../authConfig';
import TransactionList from '../components/TransactionList';
import { Navbar } from './components/navbar';
import { useNavigate } from 'react-router-dom'

export default function HistoryPage() {
    const navigate = useNavigate();

    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const baseURI = process.env.REACT_APP_BASE_URI;

    const [accessToken, setAccessToken] = useState('');
    const [transactionList, setTransactionList] = useState([]);

    let Email = '';

    const name = accounts[0] && accounts[0].name;
    const email = accounts[0] && accounts[0].username;

    if(email !== undefined) {
      // userName = name;
      Email = email;
    }

    const requestToken = async () => {
        await requestAccessToken();
    };
    
    const requestAccessToken = async () => {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };
        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
            setAccessToken(response.idToken);
        }).catch((e) => {
            console.log(e);
        });
    };

    const getTransaction = () => {
        axios.post(`${baseURI}/famzmember/v1/fyxd/member/transaction`, {
            upn: Email
        }, {
            headers: {
              Authorization: 'Bearer ' + accessToken
            }
          })
          .then(function (response) {
            setTransactionList(response.data.data.transactions);
    
        })
          .catch(function (error) {
            console.log(error);
            requestToken();
        });
    };

    const handlePlanReceipt = (id, type, status) => {
        if(type === 3)
            navigate(`/planreceipt/${id}`);
        else if(type === 1 && status === 1)
            navigate(`/successscanpay/${id}`);
    };

    useEffect(() => {
        requestToken();
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(Email.length > 0 && accessToken.length > 0)
            getTransaction();
        if(accessToken.length === 0)
            requestToken();
    // eslint-disable-next-line
    }, [Email, accessToken, isAuthenticated]);

    const authRequest = {
        ...loginRequest
    };

  return (
    <>
        <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={authRequest}
            errorComponent={Error} 
        >
            <div style={{"width": "100vw", "height": "100vh"}}>
                <Container style={{ marginTop:  "-1vh"}}>
                    <div className="mt-5 mb-4 text-center">
                        <img className="mx-auto" src={LogoFyxdHistory} alt="" style={{"display": "inline-block"}} />
                    </div>
                    <div className="mb-5">
                        {
                            transactionList.map((item) => (
                                <div className="mx-auto mt-3" key={item.id} onClick={()=> {handlePlanReceipt(item.transaction_id, item.type, item.status)}}>
                                    <div className="mx-auto history__detail w-full">
                                        <TransactionList item={item} />
                                    </div>
                                </div>
                            ))
                        }
                        {/* For Only Web App */}
                        <div className="mx-auto mt-3">
                            <div className="mx-auto history__detail">
                            </div>
                        </div>
                        <div className="mx-auto mt-3">
                            <div className="mx-auto history__detail">  
                            </div>
                        </div>
                        <div className="mx-auto mt-3">
                            <div className="mx-auto history__detail">
                            </div>
                        </div>
                        {/* For Only Web App */}
                    </div>
                    {
                        transactionList.length === 0 &&
                        <div style={{ margin: "0",
                            position: "absolute",
                            top: "45%",
                            left: "35%",
                            transform: "translateY(-50%)"}}>
                            <span className="history__timestamp">No Transaction</span>
                        </div>
                    }
            </Container>
                {/* Navbar */}
                <Navbar path="/history" />
            </div>
        </MsalAuthenticationTemplate>
    </>
  );
}