import IconWalletWhite from "../../images/icons/nav-wallet-icon-white.svg";
import IconWalletBlack from "../../images/icons/nav-wallet-icon-black.svg";
import IconPayWhite from "../../images/icons/nav-pay-icon-white.svg";
import IconPayBlack from "../../images/icons/nav-pay-icon-black.svg";
import IconHistoryWhite from "../../images/icons/nav-history-icon-white.svg";
import IconHistoryBlack from "../../images/icons/nav-history-icon-black.svg";
import IconPortfolioWhite from "../../images/icons/v3/portfolio-white-icon.svg";
import IconPortfolioBlack from "../../images/icons/v3/portfolio-black-icon.svg";
import IconDTGO from "../../images/icons/nav-dtgo-icon-color.svg";


export const Navbar = (props) => {

    return <div className="navbar">
                <a href={"#/"} className={props.path === "/" ? "active": ""}>
                    <img className="inline" alt="" src={props.path === "/" ? IconWalletWhite : IconWalletBlack}/><br/>
                    My wallet
                </a>
                <a href={"#/pay"} className={props.path === "/pay" ? "active": ""}>
                    <img className="inline" alt="" src={props.path === "/pay" ? IconPayWhite : IconPayBlack}/><br/>
                    Pay
                </a>
                <a href={"#/portfolio"} className={props.path === "/portfolio" ? "active": ""}>
                    <img className="inline" alt="" src={props.path === "/portfolio" ? IconPortfolioWhite : IconPortfolioBlack}/><br/>
                    Portfolio
                </a>
                <a href={"#/history"} className={props.path === "/history" ? "active": ""}>
                    <img className="inline" alt="" src={props.path === "/history" ? IconHistoryWhite : IconHistoryBlack}/><br/>
                    History
                </a>
                {/* <a href={"#/info"} className={props.path === "/info" ? "active": ""}>
                    <img className="inline" alt="" src={IconDTGO}/><br/>
                    Info
                </a> */}
            </div>
}