import React from 'react'
import IconTransactionSuccess from '../images/icons/history-transaction-success.svg';
import IconTransactionFail from '../images/icons/history-transaction-fail.svg';
import IconTopup from '../images/icons/v2/topup.svg';
import IconKbankTopupFail from '../images/icons/v2/kbank-topup-fail.svg';

const TransactionList = (props) => {
  const { item } = props;
  const { type, status, transaction_title, total_amount } = item;

  const renderIcon = (type, status, transaction_title) => {
    switch(type) {
      case 1:
        switch(status) {
          case 1:
            return <><img className="" alt="" src={IconTransactionSuccess} /></>;
          default:
            return <><img className="" alt="" src={IconTransactionFail} /></>;
      }
      case 2:
        switch(status) {
          case 1:
            return <><img className="" alt="" src={IconTopup} /></>;
          default:
            return transaction_title === 'Topup, Kbank' ? <><img className="" alt="" src={IconKbankTopupFail} /></> : <><img className="" alt="" src={IconTransactionFail} /></>;
        }
      case 3:
        switch(status) {
          case 1:
            return <><img className="" alt="" src={IconTopup} /></>;
          case 6:
              return <><img className="" alt="" src={IconTransactionSuccess} /></>;
          default:
            return <><img className="" alt="" src={IconTransactionFail} /></>;
        }
      case 6:
        switch(status) {
          case 1:
            return <><img className="" alt="" src={IconTopup} /></>;
          default:
            return <><img className="" alt="" src={IconTransactionFail} /></>;
        }
      case 7:
        switch(status) {
          case 1:
            return <><img className="" alt="" src={IconTopup} /></>;
          default:
            return <><img className="" alt="" src={IconTransactionFail} /></>;
        }
        case 8:
          switch(status) {
            case 1:
              return <><img className="" alt="" src={IconTransactionFail} /></>;
            default:
              return <><img className="" alt="" src={IconTransactionFail} /></>;
        }
        case 9:
          switch(status) {
            case 1:
              return <><img className="" alt="" src={IconTransactionSuccess} /></>;
            default:
              return <><img className="" alt="" src={IconTransactionFail} /></>;
        }
        case 10:
          switch(status) {
            case 1:
              return <><img className="" alt="" src={IconTransactionSuccess} /></>;
            default:
              return <><img className="" alt="" src={IconTransactionFail} /></>;
        }
      default:
        return <><img className="" alt="" src={IconTransactionFail} /></>;
    }
  }

  const renderStatus = (type, status, transaction_title, total_amount) => {
    switch(type) {
      case 1:
        switch(status) {
          case 1:
            return <><span className="ml-3" style={{"marginLeft": "12px"}}>Payment/Redeem</span></>;
          default:
            return <><span className="ml-3" style={{"marginLeft": "12px"}}>Void</span></>;
      }
      case 2:
        switch(status) {
          case 1:
            return <><span className="ml-3" style={{"marginLeft": "12px"}}>Top-up</span></>;
          default:
            return transaction_title === 'Topup, Kbank' ? <><span className="ml-3" style={{"marginLeft": "12px"}}>Top-up: cancel</span></> : <><span className="ml-3" style={{"marginLeft": "12px"}}>Void</span></>;
        }
      case 3: 
        switch(status) {
          case 1:
            return <><span className="ml-3" style={{"marginLeft": "12px"}}>Join Dai Perm</span></>;
          case 6:
              return <><span className="ml-3" style={{"marginLeft": "12px"}}>Join Dai Perm</span></>;
          default:
            return <><span className="ml-3 text-red-700" style={{"marginLeft": "12px"}}>Cancelled <strike>Dai Perm</strike></span></>;
      }
      case 6:
        switch(status) {
          case 1:
            return <><span className="ml-3" style={{"marginLeft": "12px"}}>Distribute</span></>;
          default:
            return <><span className="ml-3" style={{"marginLeft": "12px"}}>Void</span></>;
        }
      case 7:
        switch(status) {
          case 1:
            return <><span className="ml-3" style={{"marginLeft": "12px"}}>{transaction_title}</span></>;
          default:
              return <><span className="ml-3" style={{"marginLeft": "12px"}}>Void</span></>;
        }
      case 8:
          switch(status) {
            case 1:
              return <><span className="ml-3 text-red-700" style={{"marginLeft": "12px"}}>Burn</span></>;
            default:
                return <><span className="ml-3 text-red-700" style={{"marginLeft": "12px"}}>Burn</span></>;
        }
      case 9:
        switch(status) {
          case 1:
            return <><span className="ml-3" style={{"marginLeft": "12px"}}>{transaction_title}</span></>;
          default:
              return <><span className="ml-3" style={{"marginLeft": "12px"}}>Void</span></>;
        }
      case 10:
          switch(status) {
            case 1:
              return <><span className="ml-3" style={{"marginLeft": "12px"}}>{transaction_title}</span></>;
            default:
                return <><span className="ml-3" style={{"marginLeft": "12px"}}>Void</span></>;
      }
      default:
        return <><img className="" alt="" src={IconTransactionFail} /></>;
    }
  }

  const renderAmount = (type, status, transaction_title, total_amount) => {
    switch(type) {
      case 1:
        switch(status) {
          case 1:
            return <> <span className="history__amount ml-3" style={{"marginLeft": "12px"}}>
            { (status !== 1 && status !== 4) && <strike>{item.total_amount.toLocaleString()}</strike> } 
            { (status === 1 || status === 4) && <>{item.total_amount.toLocaleString()}</> }
            &nbsp;FYXD
        </span></>;
          default:
            return <><span className="ml-3" style={{"marginLeft": "12px"}}>Void</span></>;
      }
      case 3:
        switch(status) {
          case 1:
            return <><span className="history__amount ml-3" style={{"marginLeft": "12px"}}>
            { (status !== 1 && status !== 4 && status !== 6) && <strike>{item.total_amount.toLocaleString()}</strike> } 
            { (status === 1 || status === 4 || status === 6) && <>{item.total_amount.toLocaleString()}</> }
            &nbsp;FYXD
          </span></>;
          default:
            return <><span className="history__amount ml-3" style={{"marginLeft": "12px"}}>
            { (status !== 1 && status !== 4 && status !== 6) && <strike>{item.total_amount.toLocaleString()}</strike> } 
            { (status === 1 || status === 4 || status === 6) && <>{item.total_amount.toLocaleString()}</> }
            &nbsp;FYXD
          </span></>;
      }
      case 8:
        switch(status) {
          default:
            return <><span className="history__amount ml-3" style={{"marginLeft": "12px"}}>
            { <span className="text-red-700">-{item.total_amount.toLocaleString()}&nbsp;FYXD</span> } 
          </span></>;
      }
      case 9:
        switch(status) {
          case 1:
            return <> <span className="history__amount ml-3 text-green-500" style={{"marginLeft": "12px", color: "#529E6B"}}>
            { (status !== 1 && status !== 4) && <strike>+{item.total_amount.toLocaleString()}</strike> } 
            { (status === 1 || status === 4) && <>+{item.total_amount.toLocaleString()}</> }
            &nbsp;FYXD
        </span></>;
          default:
              return <> <span className="history__amount ml-3 text-green-500" style={{"marginLeft": "12px", color: "#529E6B"}}>
              { (status !== 1 && status !== 4) && <strike>{item.total_amount.toLocaleString()}</strike> } 
              { (status === 1 || status === 4) && <>{item.total_amount.toLocaleString()}</> }
              &nbsp;FYXD
          </span></>;
        }
      case 10:
          switch(status) {
            case 1:
              return <> <span className="history__amount ml-3" style={{"marginLeft": "12px"}}>
              { (status !== 1 && status !== 4) && <strike>-{item.total_amount.toLocaleString()}</strike> } 
              { (status === 1 || status === 4) && <>-{item.total_amount.toLocaleString()}</> }
              &nbsp;FYXD
          </span></>;
            default:
                return <> <span className="history__amount ml-3" style={{"marginLeft": "12px"}}>
                { (status !== 1 && status !== 4) && <strike>{item.total_amount.toLocaleString()}</strike> } 
                { (status === 1 || status === 4) && <>{item.total_amount.toLocaleString()}</> }
                &nbsp;FYXD
            </span></>;
      }
      default:
        return <><span className="history__amount ml-3" style={{"marginLeft": "12px"}}>
            { (status !== 1 && status !== 4 && status !== 6) && <strike>{item.total_amount.toLocaleString()}</strike> } 
            { (status === 1 || status === 4 || status === 6) && <>{item.total_amount.toLocaleString()}</> }
            &nbsp;FYXD
          </span></>;
          
    }
  }

  return (
    <>
        {
          renderIcon(type, status, transaction_title)
        }
        <div className="w-full">
        {
          renderStatus(type, status, transaction_title, total_amount)
        }
            <div className="w-full">
                {/* <span className="history__amount ml-3" style={{"marginLeft": "12px"}}>
                    { (status !== 1 && status !== 4) && <strike>{item.total_amount.toLocaleString()}</strike> } 
                    { (status === 1 || status === 4) && <>{item.total_amount.toLocaleString()}</> }
                    &nbsp;FYXD
                </span> */}
                {
                  renderAmount(type, status, transaction_title, total_amount)
                }
                <span className="ml-3 float-right mr-4">
                    <small className="history__timestamp">{item.created_at.replace("T", " ").replace(".000Z", "")}</small>
                </span>
            </div>
        </div>
    </>
  )
}

export default TransactionList