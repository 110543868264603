import { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/index.css';
import axios from 'axios';

import IconMoney from '../images/icons/money-icon.svg';
import IconPending from '../images/icons/pending-icon.svg';
import LogoDTGOColor from '../images/logos/dtgo-logo-color.svg';
import LogoDColor from '../images/logos/d-logo-color-flat.svg';
import IconBonus from '../images/icons/v3/bonus-icon.svg';
// import LogoFyxdMain from '../images/logos/fyxd-main-color.svg';
import LogoFyxdMyWallet from '../images/logos/fyxd-mywallet.svg'
import IconSetting from '../images/icons/v3/setting-icon.svg';
import IconTopUp from '../images/icons/v3/topup-icon.svg';
import { MsalAuthenticationTemplate, useMsal, useIsAuthenticated } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from '../authConfig';
import LogoDaidee2 from '../images/logos/daidee-2.svg';
import IconInfo from '../images/icons/v3/info-icon.svg';
import { Navbar } from './components/navbar';
import Error from './error-page';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip'

export default function AppPage() {
    const navigate = useNavigate();

    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const baseURI = process.env.REACT_APP_BASE_URI;

    const [accessToken, setAccessToken] = useState('');

    const [availablebalance, setAvailablebalance] = useState(0.00);
    const [bonusbalance, setBonusbalance] = useState(0.00);
    const [topupbalance, setTopupbalance] = useState(0.00);
    const [activeStatus,setActiveStatus] = useState('');

    const currentDate = new Date();

    let userName = '', Email = '';

    const name = accounts[0] && accounts[0].name;
    const email = accounts[0] && accounts[0].username;

    if(email !== undefined) {
        userName = name;
        Email = email;
    }

    const requestAccessToken = async () => {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };
        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
            setAccessToken(response.idToken);
        }).catch((e) => {
            console.log(e);
        });
    }

    const requestToken = async () => {
        await requestAccessToken();
    }
 

    
   

    
    const getUserProfile = () => {
        console.log(accessToken);
        axios.post(`${baseURI}/famzmember/v1/fyxd/member/dashboard`, {
            upn: Email
        }, {
            headers: {
              Authorization: 'Bearer ' + accessToken
            }
          })
        .then(function (response) {
            const { member } = response.data.data;
            let fyxd = 0;
            if(member !== undefined && member.fyxd !== undefined)
            fyxd = member.fyxd;
            let bonus = member.bonus_fyxd // already add airdrop
            let airdrop = member.airdrop_fyxd

            setAvailablebalance(fyxd + bonus);
            setTopupbalance(fyxd);
            setBonusbalance(bonus);
        })
        .catch(function (error) {
            console.log(error);
            requestToken();
        });
    };





    const getUserActive = async () => {
        // 0=inactive
        // 1=active
        // 2=suspended
        // 3=terminated
        try {
            const res = await axios.get(`${baseURI}/famzmember3/v1/fyxd/user/status`, {headers: {Authorization: 'Bearer ' + accessToken}});
            const { data } = res.data;
            console.log('Parsed data:', data);
            
            console.log('Active status:', data.active);
            switch(data.status) {
                case 0:
                    break;
                case 1:
                    break;
                case 2:
                    navigate('/suspends');
                    break;
                case 3:
                    break;
                default:
                    // Handle unknown status
                    break;
                }
            
        } catch (error) {
            console.error(error);
            requestToken();
        }
    };
  

    const handleOpenInvest = () => {
        navigate('/investment');
    };

    const handleOpenUpcomming = () => {
        navigate('/upcommingbonus');
    };

    useEffect(() => {
        if(Email.length > 0 && accessToken.length > 0)
            getUserProfile();
            getUserActive();
         
        if(accessToken.length === 0)
            requestToken();
    // eslint-disable-next-line
    }, [Email, accessToken, isAuthenticated]);

    const authRequest = {
        ...loginRequest
    };

  return (
    <>
        <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={authRequest}
            errorComponent={Error} 
        >
            <div style={{"width": "100vw", "height": "100vh"}}>
                <Container style={{"height": "70vh"}}>
                        <div className="mt-2 mb-2">
                        <img src={LogoFyxdMyWallet} alt="" style={{"marginLeft": "0.5rem", "display": "inline-block"}} />
                        <a href="#/info">
                            <img src={IconSetting} alt="" className="float-right" />
                        </a>
                        {/* <h2 className="heading--main" style={{"display": "inline-block"}}> | DTGO Rewards</h2> */}
                        </div>
                        <div className="mywallet-card">
                            <div className="mywallet-card__content">
                                <div className="mywallet__detail mt-3 mb-3">
                                    <img className="mywallet-card__dtgo-logo my-auto inline" alt="" src={LogoDTGOColor}/>
                                    <div className="vl"></div>
                                    <span className="ml-2">
                                        <span className="mywallet-card__normal-text">Welcome,</span><br/>
                                        <div className="mt-1">
                                            <span className="mywallet-card__name-text"><b>{userName ? "K. " + userName : ""}</b></span><br/>
                                            <span className="mywallet-card__email-text"><b>{Email}</b></span>
                                        </div>
                                    </span>
                                </div>
                                <hr className="mywallet-card__line"/>
                                <div className="grid grid-cols-3 gap-1 px-2">
                                    <div id="left-grid" className="col-span-2">
                                        <div style={{"display":"inline-block"}}>
                                            <div className="mt-2 mb-2">
                                                <span className="text-sm font-bold text-black">FYX-D Total Balance</span>
                                            </div>
                                            <div className="mywallet-card__balance" style={{display: "inline-block"}} >
                                                {/* <img className="mywallet-card__infinyx-logo" alt="" src="./assets/img/infinyx-logo-white.svg"/> */}
                                                <div style={{display: "inline-block"}}>
                                                    <img className="my-auto inline" alt="" src={LogoDColor} style={{width: "4.5vh", transform: "translateY(-30%)"}} />
                                                </div>
                                                <div className="" style={{display: "inline-block", marginLeft: "9px"}}>
                                                    <span style={{display: "inline-block"}}>
                                                        <span className="text-3xl sm:text-4xl md:text-5xl font-bold mywallet-card__large-text">{parseFloat(availablebalance.toString()).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</span>
                                                        {/* <span className="mywallet-card__name-text">{availablebalance === undefined ? '0' : showDigit(availablebalance)}</span> */}
                                                    </span>
                                                
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div id="right-grid" className="col-span-1">
                                        <a href={"#/topup"} style={{textDecoration:"none"}} className="mt-2 w-4/5 mywallet-card__topup-button inline-block float-right">
                                            <div className="flex justify-center items-center">
                                                <img src={IconTopUp} alt="" className="inline-block" style={{ "padding": "1.5px", "width": "1.2rem", "height":"1.2rem"}} />
                                                <div className="inline-block">
                                                    <span className="text-yyellow-700 font-semibold ml-1 text-xs">Top up</span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div className="grid grid-cols-2 gap-2 px-2">
                                    <div>
                                        <span className="text-black">FYX-D Top Up</span><br/>
                                        <span className="font-black text-xl mywallet-card__large-text">{topupbalance.toLocaleString(undefined, { minimumFractionDigits: 2 })}</span>
                                    </div>
                                    <div>
                                        <span className="text-black inline-block">FYX-D Bonus</span>
                                        <a data-tooltip-id="totalbalance-tooltip" data-tooltip-html="It is a reward given to all FAMZ by DTGO to encourage good financial practices.<br/>If you wish to participate in any FYX-D related programmes,<br/>FYX-D bonus will be used up first before using the FYX-D top up balance">   
                                            <img src={IconInfo} alt="" className="inline-block ml-1" />
                                        </a>
                                        <Tooltip id="totalbalance-tooltip" style={{ fontSize: "0.5rem"}}/>
                                        <br/>
                                        <span className="font-black text-xl mywallet-card__large-text">{bonusbalance.toLocaleString(undefined, { minimumFractionDigits: 2 })}</span><br/>
                                        {/* <span onClick={handleOpenUpcomming} className="underline text-xs text-blue-700 font-black cursor-pointer">View upcoming bonus</span> */}
                                    </div>
                                 </div>
                            </div>
                            <div className="px-1">
                                <hr className="mt-3 border-2"/>
                            </div>
                        </div>
{/* 
                        <div className="mt-3 p-2 bg-yyelow-200 border-yyellow-700 border-1 rounded-md">
                            <span className="text-md mywallet-card__large-text font-black inline-block">
                                DaiDee 2023 Bonus
                            </span>
                            <img src={IconBonus} alt="" className="float-right inline-block"/>
                            <br/>
                            <span className="text-xs mt-2">
                                <span>Dear FAMz, you will receive DaiDee 2023 bonus if...</span> 
                                <br/><br/>
                                <p>
                                &nbsp;&nbsp;&nbsp;&nbsp;&#183;You receive 35,000 DAI-DEE(equal to 35,000 FYX-D) on date/year<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&#183;DAI-DEE will be lock for 1 year, whic you will gain interest of total 17,500 DAI-DEE<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&#183;In total, you will gain 52,500 DAI-DEE in total after date/year<br/>
                                </p>  
                            </span>
                        </div>
                        */}
              
                   
                        <div style={{height: "11vh"}}>
                        </div>
                </Container>

            {/* Navbar */}
            <Navbar path="/" />
            </div>
        </MsalAuthenticationTemplate>
    </>
  );
}
