import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";

import "./index.css";
import 'react-tooltip/dist/react-tooltip.css';

import * as Sentry from "@sentry/react";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider, createHashRouter } from "react-router-dom";
import { Toaster } from 'react-hot-toast';

import Error from "./pages/error-page";

import App from "./pages/app";
import Login from "./pages/login";
import Pay from "./pages/pay";
import History from "./pages/history";
import Info from "./pages/info";
import Topup from "./pages/topup";
import Investment from "./pages/investment";
import Suspend from "./pages/suspend";
import Portfolio from "./pages/portfolio";
import PlanConsentTC from "./pages/planconsenttc";
import PlanConsentPrivacy from "./pages/planconsentprivacy";
import PlanReview from "./pages/planreview";
import PlanConfirm from "./pages/planconfirm";
import PlanCongrat from "./pages/plancongrat";
import PlanReceipt from "./pages/planreceipt";
import PlanDetail from "./pages/plandetail";
import UpcommingBonus from "./pages/upcommingbonus";
import PlanList from "./pages/planlist";
import PlanOTP from "./pages/planotp";
import PlanTransactionFail from "./pages/plantransactionfail";
import Scanpay from "./pages/scanpay";
import ReviewScanpay from "./pages/reviewscanpay";
import SuccessScanpay from "./pages/successscanpay";

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
const msalInstance = new PublicClientApplication(msalConfig);

Sentry.init({
    dsn: "https://364b9a6d48d4a626ab8dc52145989bf1@o4505917203218432.ingest.sentry.io/4505917216587776",
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
}); 

const router = createHashRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <Error />,
  },
  {
    path: "/login",
    element: <Login />,
    errorElement: <Error />,
  },
  {
    path: "/mywallet",
    element: <App />,
    errorElement: <Error />,
  },
  {
    path: "/pay",
    element: <Pay />,
    errorElement: <Error />,
  },
  {
    path: "/history",
    element: <History />,
    errorElement: <Error />,
  },
  {
    path: "/info",
    element: <Info />,
    errorElement: <Error />,
  },
  {
    path: "/suspends",
    element: <Suspend />,
    errorElement: <Error />,
  },
  {
    path: "/topup",
    element: <Topup />,
    errorElement: <Error />,
  },
  {
    path: "/investment",
    element: <Investment />,
    errorElement: <Error />
  },
  {
    path: "/portfolio",
    element: <Portfolio />,
    errorElement: <Error />
  },
  {
    path: "/planconsenttc",
    element: <PlanConsentTC />,
    errorElement: <Error />
  },
  {
    path: "/planconsentprivacy",
    element: <PlanConsentPrivacy />,
    errorElement: <Error />
  },
  {
    path: "/planreview",
    element: <PlanReview />,
    errorElement: <Error />
  },
  {
    path: "/planconfirm",
    element: <PlanConfirm />,
    errorElement: <Error />
  },
  {
    path: "/plancongrat/:id",
    element: <PlanCongrat />,
    errorElement: <Error />
  },
  {
    path: "/planreceipt/:id",
    element: <PlanReceipt />,
    errorElement: <Error />
  },
  {
    path: "/plandetail/:id",
    element: <PlanDetail />,
    errorElement: <Error />
  },
  {
    path: "/upcommingbonus",
    element: <UpcommingBonus />,
    errorElement: <Error />
  },
  {
    path: "/planlist",
    element: <PlanList />,
    errorElement: <Error />
  },
  {
    path: "/planotp",
    element: <PlanOTP />,
    errorElement: <Error />
  },
  {
    path: "/plantransactionfail",
    element: <PlanTransactionFail />,
    errorElement: <Error />
  },
  {
    path: "/scanpay",
    element: <Scanpay />,
    errorElement: <Error />
  },
  {
    path: "/reviewscanpay",
    element: <ReviewScanpay />,
    errorElement: <Error />
  },
  {
    path: "/successscanpay/:id",
    element: <SuccessScanpay />,
    errorElement: <Error />
  }
]);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <RouterProvider router={router} />
      <Toaster/>
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
