import { useState, useEffect, useRef } from 'react';
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/index.css';
import axios from 'axios';
import ReactLoading from 'react-loading';
import QRCode from 'react-qr-code';
import Pusher from 'pusher-js';
import Modal from 'react-modal';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import Webcam from 'react-webcam';
import { PNG } from 'pngjs/browser';
import jsQR from 'jsqr';
import { Buffer } from 'buffer';

import LogoDColor from '../images/logos/d-logo-color.svg';
import IconRefresh from '../images/icons/v2/refresh.svg';
import IconSuccess from '../images/icons/v2/success.svg'
import LogoFyxdPay from '../images/logos/fyxd-pay-color.svg';
import { MsalAuthenticationTemplate, useMsal, useIsAuthenticated } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from '../authConfig';
import IconFail from '../images/icons/v2/fail-redeem.svg';

import { showDigit } from '../utils/helpers';

import { useNavigate } from 'react-router-dom';
import { Navbar } from "./components/navbar";
import useQR from '../hooks/useQR';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    minHeight: '20vh',
    minWidth: '40vh'
  },
};

const videoConstraints = {
  facingMode: "environment"
};

export default function PayPage() {
    const navigate = useNavigate();
    const baseURI = process.env.REACT_APP_BASE_URI;
    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const [accessToken, setAccessToken] = useState('');
    const [countDown, setCountDown] = useState(0);
    const [runTimer, setRunTimer] = useState(true);
    const [load, setLoad] = useState(true);
    const [barcodeInfo, setBarcodeInfo] = useState("");
    const [availablebalance, setAvailablebalance] = useState(0.00);
    const [QRData, setQRData] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [CamStatus2, setCamStatus2] = useState('');

    const { QR, saveQR } = useQR();

    const webcamRef = useRef(null);

    let Email = '';
    const email = accounts[0] && accounts[0].username;

    if(email !== undefined) {
      Email = email;
    }

    const seconds = String(countDown % 60).padStart(2, 0);
    const minutes = String(Math.floor(countDown / 60)).padStart(2, 0);

    const requestToken = async () => {
      await requestAccessToken();
    };

    const requestAccessToken = async () => {
      const request = {
          ...loginRequest,
          account: accounts[0]
      };
      // Silently acquires an access token which is then attached to a request for Microsoft Graph data
      instance.acquireTokenSilent(request).then((response) => {
          setAccessToken(response.idToken);
      }).catch((e) => {
          console.log(e);
      });
    };

    const capture = () => {
        // setCamStatus2('start capture...');
        // console.log('webcamRef: ', webcamRef);
        if(webcamRef.current !== null) {
          const imageSrc = webcamRef.current.getScreenshot();
          if (imageSrc) {
            const imageBuffer = Buffer.from(imageSrc.slice('data:image/png;base64,'.length), 'base64');
            const png = PNG.sync.read(imageBuffer);
            const imageData = jsQR(Uint8ClampedArray.from(png.data), png.width, png.height);
            if (imageData) {
              setQRData(imageData.data);
            }
          }
        }
    };

    const getBarcodeInfo = () => {
        setLoad(true);
        axios.post(`${baseURI}/famzmember/v1/fyxd/member/barcodeinfo`, {
            upn: Email,
        }, {
          headers: {
            Authorization: 'Bearer ' + accessToken
          }
        })
          .then(function (response) {
            // console.log(response);

            setAvailablebalance(response.data.data.fyxd);
            setBarcodeInfo(response.data.data.barcode);
            setLoad(false);

        })
          .catch(function (error) {
            console.log(error);
            requestToken();
        });
    };


    const toggleModal2 = () => {
      setIsOpen2(!isOpen2);
    };

    const validateQRData = (data) => {
      const decodedData = atob(data);
      const decodedDataJson = JSON.parse(decodedData);

      if(decodedDataJson.endpoint === undefined || decodedDataJson.endpoint.length === 0 || decodedDataJson.qrdata === undefined || decodedDataJson.qrdata.length === 0) {
        return false;
      }

      return true;
    };

    const callFyxDGiveaway = async (decodedDataJson) => {
      try {
        const res = await axios.post(decodedDataJson.endpoint
          , {
            "qr_data" : QRData
        }, {
        headers: {
          Authorization : `Bearer ${accessToken}`
          }
        });
        navigate("/");
      } catch (error) {
        console.error(error);
        setErrorMessage(error.response.data.message);
        // alert(error)
        setIsOpen3(true);
      }
    };

    const callPay = async (decodedDataJson) => {
      try {

        navigate("/scanpay");
      } catch (error) {
        console.error(error);
      }
    }

    const confirmModal2 = async () => {
      closeModal2();
      // TODO : add loading
      const decodedData = atob(QRData);
      const decodedDataJson = JSON.parse(decodedData);

      try {
        const res = await axios.post(decodedDataJson.endpoint
          , {
            "qr_data" : QRData
        }, {
        headers: {
          Authorization : `Bearer ${accessToken}`
          }
        });
        navigate("/");
      } catch (error) {
        console.error(error);
        setErrorMessage(error.response.data.message);
        // alert(error)
        setIsOpen3(true);
      }
    };

    const closeModal2 = () => {
      setIsOpen2(false);
    };

    const closeModal3 = () => {
      setIsOpen3(false);
      navigate("/");
    };

    const toggleModal = () => {
      setIsOpen(!isOpen);
    };

    const closeModal = () => {
      setIsOpen(false);
      navigate("/");
    };

    const subscribePusher = () => {
          // Enable pusher logging - don't include this in production
          Pusher.logToConsole = true;

          var pusher = new Pusher('41f645ee262451810d50', {
            cluster: 'ap1'
          });
      
          var channel = pusher.subscribe(Email);
          channel.bind('transaction-notify', function(data) {
            // (JSON.stringify(data));
            toggleModal();
            // alert.log(`toggle ope modal`);
          });
    };

    function sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
    

    useEffect(() => {
      requestToken();
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      if(webcamRef === null || webcamRef.current === null) {
        // window.location.reload(false);
      }
    }, [webcamRef]);

    useEffect(() => {
      sleep(2000).then(() => {   if(webcamRef !== null && webcamRef.current !== null) {
        const intervalId = setInterval(capture, 100); // Check for QR code every 100ms
        return () => clearInterval(intervalId);
      } });
    }, []);

    useEffect(() => {
      console.log(QRData);
      if (QRData !== '' && validateQRData(QRData)) {
         const decodedData = atob(QRData);
         const decodedDataJson = JSON.parse(decodedData);
         saveQR(decodedDataJson.qrdata);

        if(decodedDataJson.endpoint === 'https://xa7auw07u3.execute-api.ap-southeast-1.amazonaws.com') {
          navigate('/scanpay');
        } else {
          setIsOpen2(true);
        }
      } else {
        setIsOpen2(false);
      }
    // eslint-disable-next-line
    }, [QRData]);

    useEffect(() => {
      let timerId;
  
      if (runTimer) {
        if(Email.length > 0 && accessToken.length > 0) {
          getBarcodeInfo();
        }
        setCountDown(60 * 5);
        timerId = setInterval(() => {
          setCountDown((countDown) => countDown - 1);
        }, 1000);
      } else {
        clearInterval(timerId);
      }

      if(!runTimer) {
        setRunTimer(!runTimer);
      }
  
      return () => clearInterval(timerId);
    // eslint-disable-next-line
    }, [runTimer]);
  
    useEffect(() => {
      if (countDown < 0 && runTimer) {
        // console.log("expired");
        setRunTimer(false);
        setCountDown(0);
      }
    }, [countDown, runTimer]);
    
    useEffect(() => {
      if(Email.length > 0 && accessToken.length > 0) {
        // getBalance();
        getBarcodeInfo();
        subscribePusher();
      }
      if(accessToken.length === 0)
        requestToken();
    // eslint-disable-next-line
    }, [Email, accessToken, isAuthenticated]);

    const authRequest = {
        ...loginRequest
    };
  
  return (  
    <>
       <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={authRequest}
            errorComponent={Error} 
        >
          <div style={{"width": "100vw", "height": "100vh"}}>
            <Tabs style={{"width": "100%"}}>
              <TabList style={{"width": "100%"}}>
                <Tab>MY QR</Tab>
                <Tab>SCAN QR</Tab>
              </TabList>
              <TabPanel>
                <div className="pay-heading text-center">
                    <img src={LogoFyxdPay} alt="" style={{"display": "inline-block"}} />
                </div>
                <Container className="mt-4">
                    <div className="text-center">
                        { 
                            load ? 
                            <ReactLoading className="mx-auto" type="spokes" color="#000" />
                            :
                            <QRCode className="pay-qr mx-auto" value={barcodeInfo} />
                        }
                    </div>
                    <div className="text-center" style={{"marginTop": "0.5rem"}}>
                        <span>{minutes}:{seconds}</span>
                        <button className="refresh-button ml-3" style={{"marginLeft": "0.5rem"}} onClick={()=> {getBarcodeInfo(); setCountDown(60 * 5);}}>
                          <img className="inline-block" alt="" src={IconRefresh} />
                          <span className="inline-block" style={{"marginLeft": "0.2rem"}}>Refresh QR</span>
                        </button>
                    </div>
                    <hr className="mt-3"/>
                    <div className="mt-3">
                        <div className="text-center">
                          <b>
                            <span className="mywallet-card__normal-text text-black">FYX-D Wallet Balance</span>
                          </b>
                        <br/>
                        </div>
                        <div className="text-center">
                            <img className="inline-block" alt="" src={LogoDColor} style={{width: "6vh", transform: "translateY(-30%)"}}/>
                            <b>
                              <span style={{"color": "#B58C3E", "marginLeft": "0.5rem", "fontSize": "2.5rem"}}>{parseInt(availablebalance.toString().split(".")[0]).toLocaleString()}.</span>
                              <span className="mywallet-card__name-text" style={{"color": "#B58C3E"}}>{availablebalance === undefined ? '0' : showDigit(availablebalance)}</span>
                            </b>
                        </div>
                    </div>
                </Container>
                <Modal
                  isOpen={isOpen}
                  onRequestClose={toggleModal}
                  style={customStyles}
                >
                  <div style={{textAlign : "center"}}>
                      <img className="mx-auto" src={IconSuccess} alt="" style={{width : "8vh"}}/><br/>
                      <b><span className="mt-5" style={{fontSize : "1.2rem"}}>FYX-D Point Redemption<br/>successful</span></b>
                      <button className="mt-3" style={{background: "#FFC557", borderRadius: "12px", flex: "none", width: "100%", border: "0px", color: "white", padding: "1rem"}} onClick={closeModal}>OK</button>
                  </div>
                </Modal>
              </TabPanel>
              <TabPanel>
                <span>{CamStatus2}</span>
                <Webcam
                    audio={false}
                    ref={webcamRef}
                    style={{ width: '100%'}}
                    screenshotFormat="image/png"
                    screenshotQuality={0.85}
                    videoConstraints={videoConstraints}
                  >
                </Webcam>
                  <Modal
                    isOpen={isOpen2}
                    onRequestClose={toggleModal2}
                    style={customStyles}
                  >
                  <div style={{textAlign : "center"}}>
                      {/* <img src={IconSuccess} alt="" style={{width : "8vh"}}/><br/><br/> */}
                      <b><span className="mt-5" style={{fontSize : "1.2rem"}}>Join The Campaign</span></b>
                      <button className="mt-3" style={{background: "#FFC557", borderRadius: "12px", flex: "none", width: "100%", border: "0px", color: "white", padding: "1rem"}} onClick={confirmModal2}>Confirm</button>
                      <button className="mt-3" style={{background: "transperent", borderRadius: "12px", borderColor: "black", flex: "none", width: "100%", border: "2px", color: "black", padding: "1rem"}} onClick={closeModal2}>Cancel</button>
                  </div>
                </Modal>
                <Modal
                  isOpen={isOpen3}
                  onRequestClose={closeModal3}
                  style={customStyles}
                >
                  <div style={{textAlign : "center"}}>
                      <img className="mx-auto" src={IconFail} alt="" style={{width : "8vh"}}/><br/><br/>
                      <b><span className="mt-5" style={{fontSize : "1.2rem", color: "#A04747"}}>Fail</span></b><br/>
                      <b><span style={{color: "#A04747"}}>{errorMessage}</span></b>
                      <button className="mt-3" style={{background: "#FFC557", borderRadius: "12px", flex: "none", width: "100%", border: "0px", color: "white", padding: "1rem"}} onClick={closeModal3}>OK</button>
                  </div>
                </Modal>
              </TabPanel>
            </Tabs>
            {/* Navbar */}
            <Navbar path="/pay" />
          </div>
        </MsalAuthenticationTemplate>
    </>
  );
}